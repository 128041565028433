import * as Sentry from '@sentry/browser';
import { getCurrentPagePath } from '../url';

type PageView = {
    page?: string;
    contentGroup?: string;
}

export function triggerPageView( overrides: PageView = {} ): void {
    let pageView = {
        event: 'pageview',
        page: getCurrentPagePath(),
        contentGroup: getContentGroup(),
    };

    if ( overrides && Object.keys( overrides ).length > 0 ) {
        pageView = {
            ...pageView,
            ...overrides,
        };
    }

    if ( !pageView[ 'contentGroup' ] ) {
        Sentry.captureException( new Error( 'content group undefined for pageview' ) );
    }

    window.dataLayerPush( pageView );
}

function getContentGroup(): string {
    const contentGroupElement = document.querySelector( '#page-popup-container [data-content-group]' ) as HTMLElement | null;

    if ( contentGroupElement && contentGroupElement.dataset.contentGroup ) {
        return contentGroupElement.dataset.contentGroup;
    }

    return window.contentGroup;
}
